@use '@angular/material' as mat;
@import "./theme/hcp-variables";
@import '../node_modules/ngx-heka-design-lib/styles.scss';
@import "./theme/hcp-reset";

.page-title {
  font-size: 24px !important;
}

.mdc-button.mdc-button--outlined.mat-mdc-outlined-button.mat-primary.mat-mdc-button-base {
  background-color: rgb(237, 240, 246);
  border: none;
}

a {
  text-decoration: unset;
}

.material-test {
  @include mat.elevation(3, #000, 0.5);
  background-color: mat.get-color-from-palette($webapp-primary);
}
